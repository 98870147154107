import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Box, BoxProps, Input } from '@chakra-ui/react';

import { mergeRefs } from '../../utils/form';

import 'react-datepicker/dist/react-datepicker.css';
import { formatISO } from 'date-fns';

registerLocale('pt-BR', ptBR);

const DateInput = React.forwardRef(
  (props: any, ref: React.Ref<HTMLFormElement>) => {
    const { isDisabled, throwawayRef, ...rest } = props;
    const inputRef = React.useRef();
    return (
      <Input
        ref={mergeRefs([inputRef, ref])}
        {...{ isDisabled }}
        {...rest}
        pr="0"
      />
    );
  },
);

export type DateTimePickerProps = {
  value?: string | null;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  onChange?: (
    date: string | null,
    event: React.SyntheticEvent<any, Event> | undefined,
  ) => void;
} & Omit<ReactDatePickerProps, 'selected' | 'onChange'> &
  Omit<BoxProps, 'value' | 'onChange'>;

const DateTimePicker = React.forwardRef(
  (props: DateTimePickerProps, ref: React.Ref<HTMLFormElement>) => {
    const {
      id,
      name,
      autoFocus,
      value,
      dateFormat,
      minDate,
      maxDate,
      filterTime,
      ariaDescribedBy,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      showMonthYearPicker,
      popperPlacement,
      placeholder,
      isDisabled,
      isRequired,
      isInvalid,
      isReadOnly,
      ...rest
    } = props;

    const handleOnChange = (
      date: Date | null,
      event: React.SyntheticEvent<any, Event> | undefined,
    ) => {
      onChange?.(date ? formatISO(date) : null, event);
    };

    return (
      <Box
        sx={{
          '.chakra-datepicker-wrapper': {
            display: 'block',
            borderTopEndRadius: 'lg',
            borderColor: 'gray.100',
          },
          '.chakra-datepicker-popper': {
            zIndex: 'docked',
          },
          '.react-datepicker': {
            color: 'inherit',
          },
          '.react-datepicker__header': {
            backgroundColor: 'gray.100',
          },
          '.react-datepicker-year-header': {
            color: 'inherit',
          },
          '.react-datepicker__current-month': {
            color: 'inherit',
          },
          '.react-datepicker__day-name': {
            color: 'inherit',
          },
          '.react-datepicker__navigation': {
            '&:focus-visible': {
              zIndex: 1,
              outlineColor: 'blue.500',
              borderRadius: '0.45rem',
            },
          },
        }}
        {...rest}>
        <ReactDatePicker
          id={id}
          name={name}
          autoFocus={autoFocus}
          selected={value ? new Date(value) : undefined}
          disabled={isDisabled}
          required={isRequired}
          readOnly={isReadOnly}
          dateFormat={dateFormat}
          maxDate={maxDate}
          minDate={minDate}
          filterTime={filterTime}
          locale="pt-BR"
          wrapperClassName="chakra-datepicker-wrapper"
          popperClassName="chakra-datepicker-popper"
          showPopperArrow={false}
          placeholderText={placeholder}
          ariaInvalid={isInvalid ? `${isInvalid}` : undefined}
          ariaDescribedBy={ariaDescribedBy}
          ariaRequired={isRequired ? `${isRequired}` : undefined}
          autoComplete="off"
          // https://github.com/Hacker0x01/react-datepicker/issues/2873
          customInputRef="throwawayRef"
          customInput={<DateInput {...{ ref, isDisabled, isReadOnly }} />}
          showTimeSelect
          onBlur={onBlur}
          onChange={handleOnChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          showMonthYearPicker={showMonthYearPicker}
          popperPlacement={popperPlacement}
        />
      </Box>
    );
  },
);

DateTimePicker.defaultProps = {
  dateFormat: 'Pp',
  maxDate: new Date(new Date().getFullYear(), 11, 31),
  minDate: new Date(),
};

export default DateTimePicker;
