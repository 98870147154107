import { Input, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import './remove-autocomplete-bg.css';
import { useFormControlled } from 'src/components/hook-form';
import { mergeRefs } from 'src/utils/form';

export type BaseFloatingLabelInputProps = InputProps;

export const FloatingLabelInput = forwardRef<
  HTMLInputElement,
  BaseFloatingLabelInputProps & { isUpperCase?: boolean }
>(({ placeholder, ...props }, ref) => {
  const { isUpperCase, onChange, name, ...rest } = props;
  const { field, invalid } = useFormControlled();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field?.onChange(
      isUpperCase ? event.target.value.toUpperCase() : event.target.value,
    );
    onChange?.(event);
  };

  return (
    <Input
      ref={mergeRefs([field?.ref, ref])}
      onChange={handleOnChange}
      onBlur={field?.onBlur}
      value={field?.value}
      name={field?.name}
      id={field?.name}
      isInvalid={invalid}
      h="full"
      border="none"
      placeholder=""
      w="full"
      borderRadius="lg"
      pt="4"
      px=".74rem"
      {...props}
      {...rest}
    />
  );
});
