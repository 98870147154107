import { Box, BoxProps } from '@chakra-ui/react';
import { forwardRef, ReactElement } from 'react';
import { FloatingLabelInput } from './FloatingLabelInput';
import { FloatingLabelInputNumberFormat } from './FloatingLabelInputNumberFormat';
import { FloatingLabel } from './FloatingLabel';

export const floatingLabelStyles = {
  'input:placeholder-shown + .input_label': {
    top: '50%',
    fontSize: 'md',
  },
  'input: focus + .input_label, input:not(placeholder-shown) + .input_label': {
    top: '30.5%',
    fontSize: '.8rem',
  },
};

type SimpleChildren = [
  ReactElement<typeof FloatingLabelInput> | ReactElement<typeof FloatingLabel>,
];

type NumberFormatChildren = [
  ReactElement<typeof FloatingLabelInputNumberFormat>,
  ReactElement<typeof FloatingLabel>,
];

export type FloatingLabelContainerProps = BoxProps & {
  children: SimpleChildren | NumberFormatChildren;
};

export const FloatingLabelContainer = forwardRef<
  HTMLDivElement,
  FloatingLabelContainerProps
>(({ children, ...props }, ref) => {
  return (
    <Box
      position="relative"
      as="label"
      h="14"
      w="full"
      display="flex"
      alignItems="center"
      border="1px"
      borderRadius="lg"
      borderColor="gray.600"
      _hover={{ borderColor: 'gray.600' }}
      _focusWithin={{ borderColor: '#000', border: '2px' }}
      ref={ref}
      // _active={{
      //   borderColor: '#000',
      //   border: '2px',
      //   _disabled: { borderColor: 'gray.600', border: '1px' },
      // }}
      transition="none"
      sx={floatingLabelStyles}
      {...props}>
      {children}
    </Box>
  );
});
