import { IconProps, Icon } from '@chakra-ui/icon';
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  SimpleGrid,
  SimpleGridProps,
  Tooltip,
} from '@chakra-ui/react';
import { IoHelpCircle } from 'react-icons/io5';

import { useTooltipEvents } from 'src/hooks';

type As<Props = any> = React.ElementType<Props>;

type TooltipIconProps = {
  as?: As;
  iconProps?: IconProps;
  label?: React.ReactNode;
  placementTopStart?: boolean;
} & FlexProps;

const TooltipIcon: React.FC<TooltipIconProps> = ({
  as,
  iconProps,
  label,
  placementTopStart,
  ...rest
}) => {
  const [isOpen, tooltipEvents] = useTooltipEvents();

  return (
    <Tooltip
      {...{ isOpen, label }}
      placement={`${placementTopStart ? 'top' : 'bottom'}`}
      backgroundColor="black"
      textColor="white"
      borderRadius="lg"
      p="3"
      hasArrow>
      <Flex align="center" {...tooltipEvents} {...rest}>
        <Icon {...{ as }} {...iconProps} />
      </Flex>
    </Tooltip>
  );
};

export const TooltipHelperIcon: React.FC<TooltipIconProps> = ({
  iconProps,
  ...rest
}) => (
  <TooltipIcon
    as={IoHelpCircle}
    iconProps={{ boxSize: 6, color: 'gray.200', ...iconProps }}
    {...rest}
  />
);

export const StatCard: React.FC<FlexProps> = props => (
  <Flex
    direction="column"
    border="1px solid"
    color="gray.50"
    borderRadius="md"
    px={5}
    py={4}
    {...props}
  />
);

export const StatGroup: React.FC<SimpleGridProps> = props => (
  <SimpleGrid role="group" {...props} />
);

export const StatGroupLabel: React.FC<FlexProps> = props => (
  <Flex
    fontWeight="bold"
    fontSize="lg"
    textTransform="uppercase"
    align="center"
    color="black"
    {...props}
  />
);

export const Stat: React.FC<BoxProps> = props => (
  <Box as="dl" m="0" p="0" {...props} />
);

export const StatLabel: React.FC<FlexProps> = props => (
  <Flex
    as="dt"
    p="0"
    m="0"
    h="24px"
    align="center"
    color="black"
    fontWeight="bold"
    fontSize={['xs', 'sm', 'sm', 'sm']}
    {...props}
  />
);

export const StatNumber: React.FC<FlexProps> = props => (
  <Flex
    as="dd"
    p="0"
    m="0"
    align="center"
    color="gray.400"
    fontSize="xl"
    {...props}
  />
);
