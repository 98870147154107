import { Input, InputProps } from '@chakra-ui/react';
import {
  format,
  formatISO,
  isAfter,
  isBefore,
  isValid,
  parse,
  set,
} from 'date-fns';
import React from 'react';

export type DateTimeInputProps = {
  value?: string;
  onChange?: (props: {
    value: string;
    event: React.ChangeEvent<HTMLInputElement>;
  }) => void;
  minDate?: string;
  maxDate?: string;
  dateFormat?: string;
  timeFormat?: string;
} & Omit<InputProps, 'value' | 'onChange'>;

const syncDates = (date: Date, originDate: Date) => {
  return set(date, {
    hours: originDate.getHours(),
    minutes: originDate.getMinutes(),
    seconds: 0,
  });
};

const DateTimeInput = React.forwardRef<HTMLInputElement, DateTimeInputProps>(
  (props, ref) => {
    const {
      name,
      value,
      onChange,
      minDate,
      maxDate,
      dateFormat = 'yyyy-MM-dd',
      timeFormat = 'HH:mm',
      ...rest
    } = props;
    const dateObject = value ? new Date(value) : new Date();
    const dateValue = format(dateObject, dateFormat);
    const timeValue = format(dateObject, timeFormat);
    const dateMinValue = minDate
      ? format(new Date(minDate), dateFormat)
      : undefined;
    const dateMaxValue = maxDate
      ? format(new Date(maxDate), dateFormat)
      : undefined;

    const isDateValid = (date: Date) => {
      if (!isValid(date)) return false;

      const syncedMinDate = minDate
        ? syncDates(new Date(minDate), dateObject)
        : undefined;
      if (syncedMinDate && isBefore(date, syncedMinDate)) return false;

      const syncedMaxDate = maxDate
        ? syncDates(new Date(maxDate), dateObject)
        : undefined;
      if (syncedMaxDate && isAfter(date, syncedMaxDate)) return false;

      return true;
    };

    const handleOnChangeDate: React.ChangeEventHandler<
      HTMLInputElement
    > = event => {
      const newDate = parse(event.target.value, dateFormat, dateObject);
      const syncedNewDate = syncDates(newDate, dateObject);
      if (!isDateValid(syncedNewDate)) return;

      onChange?.({ value: formatISO(syncedNewDate), event });
    };

    const handleOnChangeTime: React.ChangeEventHandler<
      HTMLInputElement
    > = event => {
      const newDate = parse(event.target.value, timeFormat, dateObject);
      if (!isDateValid(newDate)) return;

      onChange?.({ value: formatISO(newDate), event });
    };

    return (
      <>
        <Input
          ref={ref}
          name={`${name}-date`}
          type="date"
          value={dateValue}
          min={dateMinValue}
          max={dateMaxValue}
          onChange={handleOnChangeDate}
          color="#000000"
          {...rest}
        />
        <Input
          name={`${name}-time`}
          type="time"
          value={timeValue}
          color="#000000"
          onChange={handleOnChangeTime}
          {...rest}
        />
      </>
    );
  },
);

export default DateTimeInput;
