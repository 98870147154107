import { Text, TextProps } from '@chakra-ui/react';

// O FONT SIZE É CONTROLADO PELO SX DO CONTAINER UTILIZADO.

export const FloatingLabel: React.FC<TextProps> = props => {
  return (
    <Text
      _hover={{ cursor: 'text' }}
      userSelect="none"
      className="input_label"
      position="absolute"
      left=".80rem"
      transform="translate(0, -50%)"
      transition="all .1s linear"
      textColor="gray.600"
      zIndex="1"
      {...props}
    />
  );
};
