import { Box } from '@chakra-ui/react';
import { forwardRef, ReactNode } from 'react';
import { FloatingLabelContainerProps } from './FloatingLabelContainer';

const labelEffect = {
  'input:placeholder-shown + .input_label': {
    top: '50%',
    fontSize: 'md',
  },
  'input: focus + .input_label, input:not(placeholder-shown) + .input_label ': {
    top: '30.5%',
    fontSize: '.8rem',
  },
  'div + .input_label': {
    top: '30.5%',
    fontSize: '.8rem',
  },
};

type Props = FloatingLabelContainerProps & {
  addonPositon: 'left' | 'right';
  addon: ReactNode;
};

export const FloatingLabelContainerWithAddon = forwardRef<
  HTMLDivElement,
  Props
>(({ addonPositon, addon, children, ...props }, ref) => {
  return (
    <Box
      ref={ref}
      position="relative"
      as="label"
      h="14"
      w="full"
      display="flex"
      alignItems="center"
      border="1px"
      borderRadius="lg"
      borderColor="gray.600"
      _hover={{ borderColor: 'gray.600' }}
      _focusWithin={{ borderColor: '#000', border: '2px' }}
      // _active={{ borderColor: '#000', border: '2px' }}
      transition="none"
      sx={labelEffect}
      {...props}>
      {addonPositon === 'left' && addon}

      {children}

      {addonPositon === 'right' && addon}
    </Box>
  );
});
